




















































































































import Vue from 'vue'
import request from '@/utils/request'
const msgSet: any = {
    areaId: '',
    pre1h: 30,
    pre1hState: false,
    pre3h: 50,
    pre3hState: false,
    pre6h: 100,
    pre6hState: false,
    pre12h: 150,
    pre12hState: false,
    pre24h: 200,
    pre24hState: false,
    warning: false,
}
export default Vue.extend({
    computed: {
        current(): any {
            return this.$store.state.current
        },
        managerInfo(): any {
            return this.$store.state.managerInfo
        },
        areaList(): any[] {
            return this.$store.state.areaList
        },
    },
    data() {
        return {
            loading: false,
            msgSet,
            allTownList: [],
            areaName: '',
            townList: [],
        }
    },
    watch: {
        msgSet: {
            handler() {
                if(this.loading) return
                this.postMsgSet()
            },
            deep: true,
        }
    },
    mounted() {
        Promise.all([
            this.getTownList(),
            this.getMsgSet(),
        ]).then(() => {
            this.getCheckData()
        })
    },
    methods: {
        setAreaId(areaId: any) {
            this.townList = []
            this.areaList.map((e) => {
                if (e.id === areaId) {
                    this.areaName = e.name
                }
            })
            this.allTownList.map((e: any) => {
                if (areaId === e.parentId) {
                    e.checked = false
                    this.townList.push(e)
                }
            })
            if(this.msgSet.areaId == areaId) {
                this.$dialog.confirm({ message: '您正在取消定制的区域，是否确认？'})
                .then(() => this.msgSet.areaId = '')
                .catch(() => console.log('cancel'))
            } else this.msgSet.areaId = areaId
        },
        setTownId(townId: any) {
            const newArr = this.msgSet.areaId.split(',')
            if (newArr.indexOf(townId) > -1) {
                this.$dialog.confirm({ message: '您正在取消定制的区域，是否确认？'})
                .then(() => {
                    this.townList.map((e: any) => {
                        if (townId === e.id) {
                            this.$set(e, 'checked', false)
                        }
                    })
                    const arr = []as any
                    this.townList.map((e: any) => {
                        if (e.checked === true) {
                            arr.push(e.id)
                        }
                    })
                    this.msgSet.areaId = arr.join(',')
                })
                .catch(() => console.log('cancel'))
            } else {
                this.townList.map((e: any) => {
                    if (townId === e.id) {
                        this.$set(e, 'checked', true)
                    }
                })
                const arr = []as any
                this.townList.map((e: any) => {
                    if (e.checked === true) {
                        arr.push(e.id)
                    }
                })
                this.msgSet.areaId = arr.join(',')
            }
        },
        // 定制信息
        getMsgSet() {
            this.loading = true
            return request.get('/api/tobacco/rest_msg_set/info').then((res: any) => {
                if(res) {
                    this.msgSet = res
                    if (res.areaId != this.$store.state.areaId) {
                        this.$store.commit('setAreaId', res.areaId) // 重置天气
                    }
                    this.getCheckData()
                }
                setTimeout(() => this.loading = false, 90)
            })
        },
        // 提交定制信息
        postMsgSet() {
            const { msgSet } = this
            request.post('/api/tobacco/rest_msg_set', msgSet).then(() => {
                this.$notify({ type: 'success', duration: 1000, message: '设置成功'})
                this.getMsgSet()
            })
        },
        // 所有乡镇
        getTownList() {
            return request.get('/api/sys/area/all_list').then((res: any) => {
                if(res) {
                    this.allTownList = res
                }
            })
        },
        // 乡镇定制数据
        getCheckData() {
            this.townList = []
            if (this.msgSet.areaId && this.msgSet.areaId.length === 6) {
                this.areaList.map((e) => {
                    if (e.id === this.msgSet.areaId) {
                        this.areaName = e.name
                    }
                })
                this.allTownList.map((e: any) => {
                    if (this.msgSet.areaId === e.parentId) {
                        e.checked = false
                        this.townList.push(e)
                    }
                })
            } else if (this.msgSet.areaId && this.msgSet.areaId.length > 6) {
                const areaId = this.msgSet.areaId.slice(0,6)
                this.areaList.map((e) => {
                    if (e.id === areaId) {
                        this.areaName = e.name
                    }
                })
                this.allTownList.map((e: any) => {
                    if (areaId === e.parentId) {
                        e.checked = false
                        this.townList.push(e)
                    }
                })
                const areaIdArr = this.msgSet.areaId.split(',')
                this.townList.map((e: any) => {
                    areaIdArr.map((i: any) => {
                        if (e.id === i) {
                            e.checked = true
                        }
                    })
                })
            } else {
                this.areaName = ''
                this.townList = []
            }
        },
    }
})
